import React from 'react';
import containerStyle from './container.module.scss';

export const Container = ({ children, modifier, style }) => {
  let classNames = containerStyle.container;

  if (modifier) classNames += ' ' + containerStyle[modifier];
  return (
    <div className={classNames} style={style}>
      {children}
    </div>
  );
};
