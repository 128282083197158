import style from './button.module.scss';
import React from 'react';

export const Button = ({ children, modifier, icon, ...rest }) => {
  let classNames = style.button;

  if (modifier) classNames += ' ' + style[modifier];
  if (icon) classNames += ' ' + style.hasIcon;
  return (
    <button className={classNames} {...rest}>
      {icon}
      {children}
    </button>
  );
};
