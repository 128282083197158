export const isFunction = handler => typeof handler === 'function';
export const isBrowser = typeof window !== 'undefined';
export const isInShape = (point, shape) => {
  if (shape[0] !== shape[shape.length]) {
    shape[shape.length] = shape[0];
  }

  let j = 0,
    oddNodes = false,
    x = point[1],
    y = point[0],
    n = shape.length;

  for (let i = 0; i < n; i++) {
    j++;
    if (j === n) {
      j = 0;
    }

    if ((shape[i][0] < y && shape[j][0] >= y) || (shape[j][0] < y && shape[i][0] >= y)) {
      if (
        shape[i][1] +
          ((y - shape[i][0]) / (shape[j][0] - shape[i][0])) * (shape[j][1] - shape[i][1]) <
        x
      ) {
        oddNodes = !oddNodes;
      }
    }
  }

  return oddNodes;
};
