import React from 'react';
import Layout from './layout';
import StyledScrollbars from './styled-scrollbars';
import { Footer } from './footer';
import { Container } from './container';

export const ContentPage = ({ children, requireAuth = false }) => (
  <Layout requireAuth={requireAuth}>
    <StyledScrollbars>
      <div
        style={{
          position: 'relative',
          minHeight: '100%',
          paddingTop: '30px',
          paddingBottom: '170px'
        }}
      >
        <Container modifier="contentWidth">{children}</Container>
        <Footer fixed={false} />
      </div>
    </StyledScrollbars>
  </Layout>
);
