import style from './spinner.module.scss';
import React from 'react';

export const Spinner = ({ modifier }) => {
  let classNames = `${style.spinner}`;

  if (modifier) classNames += ' ' + style[modifier];

  return (
    <div className={classNames}>
      <div className={style.bounce1} />
      <div className={style.bounce2} />
      <div className={style.bounce3} />
    </div>
  );
};
