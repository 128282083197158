import React from 'react';
import { Dialog } from '@reach/dialog';
import style from './popup-dialog.module.scss';

const PopupDialog = ({ onClose, children, image }) => (
  <Dialog className={style.popup}>
    <img src={image} alt="" style={{ position: 'absolute', top: -40, zIndex: 10, right: 80 }} />
    <div className={style.topBar}>
      <span className={style.closeIcon} onClick={onClose}>
        ✕
      </span>
    </div>
    {children}
  </Dialog>
);

export default PopupDialog;
