import React, { Component } from 'react';
// import { Link, navigate } from '@reach/router';
import style from '../pages/login.module.scss';
import { Button } from '../components/button';
import { Spinner } from '../components/spinner';
import { UserContext } from '../context/UserContext';
import { login } from '../api/user';
import PopupDialog from '../components/popup-dialog';
import { RegisterModal } from '../components/module-registration-modal';
import { ConfirmationModal } from './module-confirmation-modal';

export default class LoginModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      error: null,
      isSending: false,
      isRegisterModal: false,
      isTextOnly: false
    };
  }

  onUserNameChange = e => {
    this.setState({ username: e.target.value });
  };

  onPasswordChange = e => {
    this.setState({ password: e.target.value });
  };

  toggleRegisterModalChange = e => {
    this.setState({ isRegisterModal: !this.state.isRegisterModal });
  };

  toggleConfirmationModalChange = e => {
    this.setState({ isTextOnly: !this.state.isTextOnly, isRegisterModal: false });
    this.context.closeLogin();
  };

  onSubmit = e => {
    e.preventDefault();
    this.setState({ error: null, isSending: true });

    login({
      username: this.state.username,
      password: this.state.password,
      params: this.context && this.context.params ? this.context.params : false
    })
      .then(([user, err]) => {
        if (err) {
          return this.setState({ isSending: false, error: err.message });
        }

        this.setState({ isSending: false, username: '', password: '' });
        this.context.login(user);
        this.context.closeLogin();
        // const linkBackTo =
        //   (this.props.location &&
        //     this.props.location.state &&
        //     this.props.location.state.linkBackTo) ||
        //   '/';
        // navigate(linkBackTo);
      })
      .catch(e => {
        console.error(e);
        // something completely unexpected happened, since `login()` is only supposed to resolve
        this.setState({ error: 'Es ist etwas schief gelaufen.', isSending: false });
      });
  };

  afterRegister = () => {
    this.setState({ isTextOnly: true });
  };

  render() {
    const { username, password, error, isSending, isRegisterModal, isTextOnly } = this.state;
    return (
      <>
        <UserContext.Consumer>
          {user =>
            Boolean(user.loginOpen) && (
              <PopupDialog
                onClose={() => {
                  this.setState({ isRegisterModal: false });
                  if (isTextOnly) {
                    this.toggleConfirmationModalChange();
                  }
                  user.closeLogin();
                }}
              >
                {isTextOnly ? (
                  <ConfirmationModal
                    toggleConfirmationModalChange={this.toggleConfirmationModalChange}
                  />
                ) : isRegisterModal ? (
                  <RegisterModal
                    toggleRegisterModalChange={this.toggleRegisterModalChange}
                    afterRegister={this.afterRegister}
                    user={user}
                  />
                ) : (
                  <div className={style.login}>
                    <h2>Login</h2>
                    {user.action && user.action === 'like' ? (
                      <p>Bitte loggen Sie sich ein, um die Like-Funktion nutzen zu können.</p>
                    ) : (
                      ''
                    )}
                    {user.action && user.action === 'claim' ? (
                      <p>
                        Vielen Dank für Ihren Kommentar. Wir haben ihn erhalten und gespeichert.
                        <br />
                        Bitte loggen Sie sich nun ein, um den Kommentar freizuschalten.
                      </p>
                    ) : (
                      ''
                    )}
                    <form onSubmit={this.onSubmit}>
                      <div className={style.loginGroup}>
                        <label>
                          <span>Benutzername</span>
                          <input
                            required
                            placeholder="Ingrid Maier"
                            type="text"
                            name="username"
                            value={username}
                            onChange={this.onUserNameChange}
                          />
                        </label>
                        <br />
                        <label>
                          <span>Passwort</span>
                          <input
                            required
                            placeholder="********"
                            type="password"
                            name="password"
                            value={password}
                            onChange={this.onPasswordChange}
                          />
                        </label>
                        <br />
                        {error && <div className="formHint">{error}</div>}
                      </div>
                      <div>
                        <Button
                          type="submit"
                          modifier={isSending && 'loading'}
                          disabled={isSending}
                        >
                          {isSending && <Spinner />}
                          Einloggen
                        </Button>
                      </div>
                    </form>
                    <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                      Noch kein Profil?{' '}
                      <span
                        style={{
                          textDecoration: 'underline',
                          color: 'blue',
                          cursor: 'pointer'
                        }}
                        onClick={this.toggleRegisterModalChange}
                      >
                        Registrieren Sie sich jetzt
                      </span>{' '}
                      und werden Sie Teil der Community. <br />
                      <small>
                        <a
                          style={{ textDecoration: 'none', color: 'black', opacity: '0.7' }}
                          target="_blank"
                          href="/imprint"
                        >
                          Warum muss ich mich registrieren
                        </a>
                        ?
                      </small>
                    </div>
                  </div>
                )}
              </PopupDialog>
            )
          }
        </UserContext.Consumer>
      </>
    );
  }
}

LoginModal.contextType = UserContext;
