import React, { Component } from 'react';
import { Button } from '../components/button';

export class ConfirmationModal extends Component {
  render() {
    const { toggleConfirmationModalChange } = this.props;
    return (
      <div>
        <h2>Vielen Dank für Ihre Registrierung. </h2>
        <div>
          Sie erhalten zeitnah eine Mail, mithilfe derer Sie Ihre Registrierung per Link bestätigen
          können. Danach loggen Sie sich einfach ein und können anschließend alle Funktionen der
          Plattform nutzen!
        </div>
        <Button style={{ marginTop: '20px' }} onClick={toggleConfirmationModalChange}>
          OK
        </Button>
      </div>
    );
  }
}
